import { PlusIcon } from '@heroicons/react/20/solid'
import { type LoaderFunctionArgs, type MetaFunction } from '@remix-run/node'
import { Link, json, useLoaderData } from '@remix-run/react'
import { type ColumnDef } from '@tanstack/react-table'
import { PiUsersThree } from 'react-icons/pi'
import { z } from 'zod'
import { zx } from 'zodix'
import GenericErrorBoundary from '#app/components/error-boundary'
import Hero from '#app/components/hero'
import { SearchQuery } from '#app/components/search/search-query'
import { Avatar, AvatarFallback, AvatarImage } from '#app/components/ui/avatar'
import { LinkButton } from '#app/components/ui/button'
import { DataTable } from '#app/components/ui/data-table'
import HeaderSimple from '#app/components/ui/header'
import { useHints } from '#app/utils/client-hints'
import { getApiClient } from '#app/utils/service-api.server'
import { getInitials } from '#app/utils/string'
import { type CompleteCandidate } from '#types/api-client.types.gen'
import { candidateOrderingSchema } from '#types/api-client.types.zod'
import { listCandidates } from '#app/.server/api-client/sdk.gen'

export const meta: MetaFunction = () => [{ title: 'Candidates | Moonhub' }]
const PAGE_SIZE = 25

// Extract the types for order_by and order_direction
// Extract the order_by schema
type OrderByType = z.infer<typeof candidateOrderingSchema.shape.order_by>
type OrderDirectionType = z.infer<
	typeof candidateOrderingSchema.shape.order_direction
>

// Define the sort by schema with transformation and validation
export const candidateSortBySchema = z
	.string()
	.transform((value) => {
		const [order_by = 'created_at', order_direction = 'desc'] = value.split(
			'.',
		) as [OrderByType, OrderDirectionType]
		return { order_by, order_direction }
	})
	.refine(
		({ order_by, order_direction }) => {
			return (
				candidateOrderingSchema.shape.order_by.safeParse(order_by)
					.success &&
				candidateOrderingSchema.shape.order_direction.safeParse(
					order_direction,
				).success
			)
		},
		{
			message: 'Invalid sort format or values.',
		},
	)

export async function loader({ request }: LoaderFunctionArgs) {
	// parse our query params with zodix
	const {
		page = 0,
		limit = PAGE_SIZE,
		sort,
		q: query,
	} = zx.parseQuery(request, {
		page: z.coerce.number().default(0),
		limit: z.coerce.number().min(1).max(500).optional().default(PAGE_SIZE),
		sort: candidateSortBySchema.default('created_at.desc'),
		q: z.string().optional().nullable(),
	})
	const { order_by, order_direction } = sort

	const coreClient = await getApiClient(request)
	const { data: candidates } = await listCandidates({
		client: coreClient,
		body: {
			pagination: {
				page,
				page_size: limit,
			},
			ordering: [
				{
					order_by,
					order_direction,
				},
			],
			filters: {
				query,
			},
		},
	})

	return json({ ...candidates })
}

export const columns: ColumnDef<CompleteCandidate>[] = [
	{
		accessorKey: 'name',
		header: 'Name',
		enableSorting: false,
		cell: ({ row }) => {
			// get the name and logo from the row
			const id = row.original.id
			const name = row.original.name ?? 'N/A'
			const avatarImage = row.original.profile_pic
			// const avatarImage = undefined

			// get the initials from the name
			const initials = getInitials(name)

			return (
				<div className="flex items-center gap-x-2">
					<Avatar className="flex h-8 w-8 flex-none items-center justify-center rounded-full bg-gray-200 text-lg text-gray-600">
						{avatarImage && (
							<AvatarImage
								src={avatarImage}
								className="rounded-full"
							/>
						)}
						<AvatarFallback>{initials}</AvatarFallback>
					</Avatar>
					<div className="flex flex-grow flex-col gap-y-0.5">
						<div className="flex flex-grow flex-row gap-x-3">
							<div className="flex">
								<Link to={`/admin/candidates/${id}`}>
									{name}
								</Link>
							</div>
						</div>
					</div>
				</div>
			)
		},
	},
	{
		accessorKey: 'current_position',
		header: 'Job Title',
		enableSorting: false,
		cell: ({ row }) => {
			return <div>{row.original.job_title ?? '-'}</div>
		},
	},
	{
		accessorKey: 'current_company',
		header: 'Company',
		enableSorting: false,
		cell: ({ row }) => {
			return <div>{row.original.current_company}</div>
		},
	},
	// {
	// 	accessorKey: 'current_salary',
	// 	header: 'Salary',
	// 	enableSorting: false,
	// 	cell: ({ row }) => {
	// 		if (!row.original.equity_expectations) {
	// 			return <div>-</div>
	// 		}

	// 		const currencyFormatter = new Intl.NumberFormat(undefined, {
	// 			style: 'currency',
	// 			currency: 'USD',
	// 		})
	// 		return (
	// 			<div>{currencyFormatter.format(row.original.equity_expectations)}</div>
	// 		)
	// 	},
	// },
	{
		accessorKey: 'owner',
		header: 'Owner',
		enableSorting: false,
		cell: ({ row }) => {
			// get the initials from the name
			const initials = getInitials(row.original.owner_name ?? 'N/A')
			const owner_picture = row.original.owner_picture

			return (
				<Link
					className="flex items-center gap-x-2"
					to={`/admin/users/${row.original.owner_id}`}
				>
					<Avatar className="flex h-8 w-8 flex-none items-center justify-center rounded-full bg-gray-200 text-lg text-gray-600">
						{owner_picture && (
							<AvatarImage
								src={owner_picture}
								className="rounded-full"
							/>
						)}
						<AvatarFallback>{initials}</AvatarFallback>
					</Avatar>
					<div className="flex flex-grow flex-col gap-y-0.5">
						<div className="flex flex-grow flex-row gap-x-3">
							<div className="flex">
								{row.original.owner_name}
							</div>
						</div>
					</div>
				</Link>
			)
		},
	},
	{
		accessorKey: 'created_at',
		header: 'Created',
		enableSorting: true,
		cell: function CellComponent({ row }) {
			const { timeZone } = useHints()
			const display = new Intl.DateTimeFormat(undefined, {
				timeZone,
				dateStyle: 'medium',
			}).format(new Date(row.getValue('created_at')))

			return <div>{display}</div>
		},
	},
]

export default function CandidatesIndex() {
	const { entries: candidates, metadata } = useLoaderData<typeof loader>()

	return (
		<div className="flex h-full flex-1 flex-col gap-x-5">
			<HeaderSimple
				title="Candidates"
				subtitle={
					'Manage your candidates and their associated jobs, messages, and more.'
				}
				className="flex flex-none"
			>
				<div className="flex items-center gap-x-5">
					{/* <Button variant="white" size="sm">
						<BiFilterAlt className="h-4 w-4" />
						Filter
					</Button> */}
					{/* <LinkButton to={'new'} variant="default" size="sm">
						<PlusIcon className="h-4 w-4" />
						Add Candidate
					</LinkButton> */}
				</div>
			</HeaderSimple>
			<SearchQuery name="q" placeholder="Search candidates" autoFocus />
			<div className="flex-grow overflow-hidden">
				{candidates?.length && candidates.length > 0 ? (
					<DataTable
						data={candidates}
						columns={columns}
						totalRows={metadata?.pagination?.total_count ?? 0}
						pageSize={metadata?.pagination?.page_size ?? PAGE_SIZE}
						sortProps={{
							initialField:
								metadata?.ordering?.[0]?.order_by ??
								'created_at',
							initialOrder:
								metadata?.ordering?.[0]?.order_direction ??
								undefined,
						}}
					/>
				) : (
					<Hero
						icon={PiUsersThree}
						title="No candidates"
						description="Get started by creating a new candidate."
					>
						<div className="mt-6">
							<LinkButton
								to={'/admin/candidates/new'}
								variant={'default'}
								size={'lg'}
							>
								<PlusIcon
									className="-ml-0.5 mr-2 h-5 w-5"
									aria-hidden="true"
								/>
								New Candidate
							</LinkButton>
						</div>
					</Hero>
				)}
			</div>
		</div>
	)
}

// need the generic error boundary
export const ErrorBoundary = GenericErrorBoundary
